import { getCurrentInstance } from "vue";
import { useStore } from "vuex";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import addWindowScript from "@/client/extensions/composition/addWindowScript";

export default (props) => {
  let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(
    props,
    useStore()
  );
  let { addScript } = addWindowScript();
  //TODO: validate instance.performWithUi
  //setup context
  let instance = getCurrentInstance();

  let googleAuth2 = false;

  let defaultGoogleLoginElementId = "saffron-google-login-element";

  let scopes = "profile";

  let googleApiLoaded = false;

  let facebookApiLoaded = false;

  let finalGoogleLoginCallback = false;

  let finalFacebookLoginCallback = () => {};

  let warnIfNoPerformWithUi = (instance) => {
    if (typeof instance.ctx.performWithUi !== "function") {
      utilities.debug(
        "error: the useSocialLogin composition expects the performWithUi method to be present on instance (usually comes from plugin). Please include the plugin, or provide an implementation",
        2,
        instance
      );
    }
  };

  let loadFacebookSdk = async () => {
    if (facebookApiLoaded) {
      return true;
    }

    let locale =
      typeof instance.ctx.getLocale === "function"
        ? instance.ctx.getLocale()
        : "en-US";

    await addScript("https://connect.facebook.net/" + locale + "/sdk.js", {});
    FB.init({
      appId: config.facebook.appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v14.0",
    });
    facebookApiLoaded = true;
    return true;
  };

  let loadGoogleSdk = async () => {
    if (googleApiLoaded) {
      return true;
    }

    // prepare for one click signin
    await addScript("https://accounts.google.com/gsi/client", {});

    if (finalGoogleLoginCallback) {
      google.accounts.id.initialize({
        client_id: config.google.clientId,
        callback: verifyGoogleLogin,
      });
    }
    googleApiLoaded = true;
    return true;
  };

  let createGoogleLoginButton = async (
    el = defaultGoogleLoginElementId,
    cb
  ) => {
    // order is very important. api loading checks for cb!
    if (cb) {
      finalGoogleLoginCallback = cb;
    }
    let apiLoadResult = await instance.ctx.performWithUi(loadGoogleSdk, {
      successText: false,
      errorText: "core.socialLogin.google.errorLoadingApi",
    });

    let elId;

    if (!el) {
      el = defaultGoogleLoginElementId;
    }
    if (typeof el === "string") {
      elId = el;
      el = document.getElementById(el);
    } else {
      elId = el.id;
    }

    if (!el) {
      el = document.createElement("div");
      el.id = defaultGoogleLoginElementId;
      document.body.appendChild(el);
    }

    //   el.style.display = 'none';
    // init the button element and click it's trigger
    google.accounts.id.renderButton(el, { theme: "outline" });
  };

  let activateGoogleLoginButton = async (
    input = defaultGoogleLoginElementId
  ) => {
    let elId = typeof input === "string" ? input : input.id;
    document.querySelector("#" + elId + ' [tabindex="0"]').click();
  };

  let startGoogleLogin = async () => {
    warnIfNoPerformWithUi(instance);

    // load api
    let apiLoadResult = await instance.ctx.performWithUi(loadGoogleSdk, {
      successText: false,
      errorText: "core.socialLogin.google.errorLoadingApi",
    });

    if (!apiLoadResult) {
      return false;
    }

    // init google accounts, and propmt for login
    let signInResult = await new Promise((fulfil, reject) => {
      google.accounts.id.prompt((result) => {
        result.h ? fulfil(true) : reject(false);
      });
    }).catch(() => {});

    if (signInResult) {
      return true;
    }

    // prompt failed. this is common. lets try then, the button
    // make sure we have a button element, injecting it if missing
    await createGoogleLoginButton(defaultGoogleLoginElementId);
    activateGoogleLoginButton(defaultGoogleLoginElementId);

    return;
    let elId = defaultGoogleLoginElementId;
    let el = document.getElementById(elId);
    if (!el) {
      el = document.createElement("div");
      el.id = elId;
      document.body.appendChild(el);
    }

    el.style.display = "none";
    // init the button element and click it's trigger
    google.accounts.id.renderButton(el, { theme: "outline" });
    document.querySelector("#" + elId + ' [tabindex="0"]').click();
  };

  let verifyGoogleLogin = async (googleResponse) => {
    let loginData = {
        clientId: googleResponse.clientId,
        credential: googleResponse.credential,
      },
      result;

    warnIfNoPerformWithUi(instance);
    await instance.ctx.performWithUi(
      async () => {
        result = await asyncOps.asyncCall(
          "token/refresh/googleSignIn",
          loginData,
          { method: "post" }
        );
        return !result.isError;
      },
      { successText: false }
    );

    if (result.isError) {
      // error handled with instance.ctx.performWithUi
      return false;
    }

    return finalGoogleLoginCallback(result.data);
  };

  let loginWithGoogle = async (finalCB) => {
    finalGoogleLoginCallback = finalCB;
    return startGoogleLogin();
  };

  let startFacebookLogin = async () => {
    if (utilities.isSSR()) {
      return true;
    }

    warnIfNoPerformWithUi(instance);

    await instance.ctx.performWithUi(loadFacebookSdk, { successText: false });

    FB.login(
      (response) => {
        verifyFacebookLogin(response);
      },
      { scope: "public_profile,email" }
    );
  };

  let verifyFacebookLogin = async (facebookResponse) => {
    let authData = facebookResponse.authResponse;
    let loginData = {
        accessToken: authData.accessToken,
        userId: authData.userID,
      },
      result;

    let facebookLogin = async () => {
      result = await asyncOps.asyncCall(
        "token/refresh/facebookLogin",
        loginData,
        { method: "post" }
      );
      return !result.isError;
    };

    warnIfNoPerformWithUi(instance);
    await instance.ctx.performWithUi(facebookLogin, { successText: false });

    // error handled with performWithUi, success handled with loginSuccessHandler
    if (result.isError) {
      return false;
    }
    finalFacebookLoginCallback(result.data);
  };

  let loginWithFacebook = (finalCB) => {
    finalFacebookLoginCallback = finalCB;
    return startFacebookLogin();
  };

  let setGoogleLoginCallback = (cb) => {
    finalGoogleLoginCallback = cb;
  };

  return {
    loadGoogleSdk,
    loadFacebookSdk,
    loginWithGoogle,
    loginWithFacebook,
    createGoogleLoginButton,
    activateGoogleLoginButton,
    setGoogleLoginCallback,
  };
};
